import {setObjectGen} from '../../../helpers';

const objectives = [
    'Develop engineers who possess basic knowledge and skills in the planning design, construction, management and operation, and maintenance of engineering works',
    'Develop engineers who take into account the effects to the environment of the infrastructure they will construct and the electrical system they will set up',
    'Develop engineers who are fully aware of their legal and ethical responsibilities',
    'Develop engineers who are knowledgeable in human, financial, physical energy and resource management; and',
    'Develop engineers who are fully oriented in the arts, science, technology, and engineering concepts.',
];

const objectivesIT = [
    'Apply mathematical concepts, algorithmic principles, and computer science theory in the modeling and design of ICT solutions',
    'Use analytical skills and appreciate the dynamic and collaborative nature of problem solving',
    'Handle moral, legal, and social responsibilities',
    'Recognize the need for continuing professional development and lifelong learning',
    'Engage in technopreneurial activities utilizing appropriate tools in the field of information technology and who are globally competitive technopreneurs',
    'Respond to innovations and diverse perspectives by using a wide range of ideas and techniques that will demonstrate originality and creativity in application development',
    'Increase accessibility of ICT resources that will promote efficient and effective communication to various clienteles',
    'Develop appropriate skills that are essential for independent learning in different areas of programming, networking, database management and troubleshooting',
    'Apply known methods and skills, and learn new approaches based on current trends in order to develop applications that are ready for deployment',
    'Understand the real world limits in order to evaluate and select new information resources and technological innovations based on the appropriate task that will demonstrate originality and creativity in application development'
];

const facultyAndStaff = [
    setObjectGen('Marben RAMOS ', 'DEAN'),  
    setObjectGen('Shiela A. Alamil ', 'Department Secretary'),    
    setObjectGen('Mariano P. ADUPE JR.'),
    setObjectGen('Rosemarie E. ANTE '),
    setObjectGen('Joy Abegail D. APULI '),
    setObjectGen('Jenisen Ivy A. AYDALLA '),
    setObjectGen('Marjorie BANTON, '),
    setObjectGen('Jay O. BENARABA'),
    setObjectGen('Jennifer B. BLACER '),
    setObjectGen('Rhodora Faye A. BROSAS '),
    setObjectGen('Reilan L. CADUBLA '),
    setObjectGen('Ivan L. CAUBANG '),
    setObjectGen('Francis Lester S.B DREU '),
    setObjectGen('Paulo Fortono ESTERNON '),
    setObjectGen('Rovin Leian J. IMPERIAL '),
    setObjectGen('Kenneth Ryan LLORERA '),
    setObjectGen('Rachell Ann G. MADRASO '),
    setObjectGen('Aljohn MARILAG '),
    setObjectGen('Lee Michael I. MATA '),
    setObjectGen('Charlie MILLENA '),
    setObjectGen('Cristina NUAL '),
    setObjectGen('Shaira Lee H. PEPAÑO'),
    setObjectGen('Janine M. QUINTAL'),
    setObjectGen('Jervy  Anne M. ROMERO '),
    setObjectGen('Jian Benedick V. RONTAS '),
    setObjectGen('Aira M. SALAZAR '),
    setObjectGen('JP Remar A. SERRANO '),
    setObjectGen('Edmar C. TOLEDO '),
    setObjectGen('Mark Jovan R. TEE, '),
    setObjectGen('Jerrick L. VIRREY '),
    setObjectGen('Erlando A. ZURBITO III '),

];

const courses = [
    setObjectGen('Bachelor of Science in Electrical Engineering (BSEE)'),
    setObjectGen(
        'Bachelor of Science in Civil Engineering (BSCE)',
        setObjectGen('Majors in:', ['Construction Engineering and Management', 'Structural Engineering'])
    ),
    setObjectGen(
        'Bachelor of Science in Computer Science (BSCS)',
        //setObjectGen('Specialized in:', ['Robotics', 'Software Development'])
    ),
    setObjectGen(
        'Bachelor of Science in Information Technology (BSIT)',
        setObjectGen(null, ['Multimedia Technology', 'Web Development Technology'])
    ),
    setObjectGen('Bachelor of Library and Information Science (BLIS)'),
];
const fbpage =[];

export const schoolOfEngineeringAndComputerStudies = {
    objectives,
    objectivesIT,
    facultyAndStaff,
    courses
}