import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners} from '../../../data/web';
import {Paragraph} from '../../../components/atoms/web';
import {RenderList} from '../../../components/molecules/web';
import {juniorHighSchool} from '../../../data/web/Admission';

const JuniorHighSchool = () => { 

    const {grade7, transferees,oldstudents,contacts,registrar,assessment} = juniorHighSchool;

    return (
        <BaseWithBannerAndOverview data={navlinks.admission.items} title='Junior High School Department' banner={banners.AdmissionBannerObj}>
            <Paragraph color='primary' variant='h6' bold> Incoming Grade 7 </Paragraph>
            <RenderList header='Application Requirements :' data={grade7} />
            <Paragraph color='primary' variant='h6' bold> For Transferees/Returnees (Grades 8-10) </Paragraph>
            <RenderList header='Application Requirements :' data={transferees} />
            <RenderList header='Junior High School AGTC:' data={contacts} />
            <RenderList header='Registrar and Cashier:' data={registrar} />
            <RenderList header='Assessment:' data={assessment} />
        </BaseWithBannerAndOverview>
    )
}

export default JuniorHighSchool
