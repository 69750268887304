import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners, contactUs} from '../../../data/web';
import {Paragraph} from '../../../components/atoms/web';
import {RenderList} from '../../../components/molecules/web';
import {college} from '../../../data/web/Admission';

const College = () => { 

    const {procedure, requirements,contacts} = college;

    return (
        <BaseWithBannerAndOverview data={navlinks.admission.items} title='College' banner={banners.AdmissionBannerObj}>
            <Paragraph variant='h6' color='primary' bold>ENROLLMENT PROCEDURE</Paragraph>
            <RenderList header='Admission Requirements' data={procedure} />
            <RenderList header='Additional Requirements for Transferees and Second Coursers from other Schools' data={requirements} />
            <RenderList header='College AGTC' data={contacts} />
        </BaseWithBannerAndOverview>
    )
}

export default College
