import { setObjectGen } from '../../../helpers';

const objectives = [
    setObjectGen('', [
        'Provide students with relevant and dynamic curricula in accountancy, management accountancy, business administration, entrepreneurship, and office administration',
        'Enhance the students’ personality and academic preparation to strengthen their knowledge and skills in the various areas of business towards global competitiveness',
        'Develop the research potentials of the students as a way of contributing to the existing knowledge and practices',
        'Strengthen students’ well-being through a rewarding vocation as young business professionals',
        'Provide the business community with competent, innovative, proficient, multi-skilled, and socially-responsive graduates and',
        'Establish partnerships with the industry and other institutions in relevant areas such as, but not limited to, instruction, research, extension services, and on the job training, as a way to enhance the students’ knowledge skills, and personality',
    ])
];

const courses = [
    setObjectGen('', [
        'Bachelor of Science in Accountancy (BSA)',
        'Bachelor of Science in Management Accounting (BSMAC)',
        'Bachelor of Science in Internal Auditing (BSIAI)',
        'Bachelor of Science in Business Administration (BSBA)',
    ]),
    setObjectGen('Majors in:', [
        'Marketing Management (BSBA - MM)',
        'Operations Management (BSBA - OM)',
        'Financial Management (BSBA - FM)',
        'Human Resource Management (BSBA - HRM)',
    ])
];

const facultyAndStaff = [
    setObjectGen('Jean A. MIRANDILLA, DBA ', 'Dean'),      
    setObjectGen('Chona BERNARDO, MEcon, LPT, PHD PA (Dissertation Writing)', 'Associate Dean'), 
    setObjectGen('Shaina Memoracion ','Department Secretary'),
    setObjectGen('Faculty:'), 
    setObjectGen('BSA/BSMAC/BSIA'), 
    setObjectGen('Christine Loise BELLEZA, CPA'), 
    setObjectGen('Atty.Wilfredo BUSALLA, CPA, MBA'), 
    setObjectGen('Atty.Hyacinth ENRIQUEZ, CPA'), 
    setObjectGen('Melquiades CANCELA, CPA'),
    setObjectGen('Ma. Daria LABALAN, CPA, MBA'), 
    setObjectGen('Rachel Anne MONJARDIN, MBA, CPA'), 
    setObjectGen('Atty.Nikka Aileen PEJO, CPA'), 
    setObjectGen('Bernadeth Sales, CPA'), 
    setObjectGen('Yoshua Daryl Urbina, CPA'), 
    setObjectGen('BSBA'),
    setObjectGen('Rev. Fr.Bernhard ABRAZADO, SVD, MBA'), 
    setObjectGen('MarivicArmario, LPT, MM (Thesis Writing)'), 
    setObjectGen('Bhey Apuli, MBA (Units)'), 
    setObjectGen('Angelica Apuli, MBA '), 
    setObjectGen('Miriam Manalo, EdD, LPT'),
    setObjectGen('Joenel Reamico, LPT'), 
    setObjectGen('Benjie Sales, JD'), 
    setObjectGen('Josefina Salire, MPA'), 
    setObjectGen('Ma. Lourdes Segovia, MBA'), 
    setObjectGen('Agnes Somera, MBA'), 
    setObjectGen('Anne Tablizo, MBA (Thesis Writing)'), 
   
   
]

export const schoolOfBusinessManagementAndAccountancy = {
    objectives,
    courses,
    facultyAndStaff
}

