import {setObjectGen} from '../../../helpers';

const courses = [
    setObjectGen('', [
        'Bachelor of Science in Psychology (BS PSYCH)',
        'Bachelor in Human Services (BHS)',
        'Bachelor of Physical Education (BPED)',
        'Bachelor of Special Needs Education (BSNED)',
        'Bachelor of Elementary Education (BEED)',
        'Bachelor of Secondary Education (BSED)',
    ]),
    setObjectGen('Majors in:', [
        'English',
        'Science',
        'Mathematics',
        'Filipino',
    ]),
];

const facultyAndStaff = [
    setObjectGen('Dr. Marites A. Manganan, LPT ', 'Dean'),
    setObjectGen('Ms. Cherry Espartinez ', 'Department Secretary'),
    setObjectGen('Full Time Faculty', 'Part Time Faculty'),
    setObjectGen('Hyacinth A. ASAYTUNO ','Emma T. ALCANTARA '),
    setObjectGen('Thea Marie P. BALUIS ','Inezita R. ALPAJARO '),
    setObjectGen('Rosean A. BATAS ','Sara Jane ANDES '),
    setObjectGen('Fraulein L. BELLECA ','Bebian ALPARCE '),
    setObjectGen('Grace Anne B. BERMEJO ','Angela A. ATUN '),
    setObjectGen('Neca B. BONGON ','Anne Marie A. AUSTERO '),
    setObjectGen('KEN ANDREI CUATRO ','Dr. Jim Rey R. BALOLOY '),
    setObjectGen('Ma. Jemilyn A. DATU ','Chona S. BERNARDO '),
    setObjectGen('Mary Ann G. DE LARA ','Fr. Remegio DOMINO '),
    setObjectGen('Pevi Mariz F. DE OCAMPO ','Ching SB. DREU '),
    setObjectGen('Lorenzo B. FOLLOSO ','Aubrey N. GALLANO '),
    setObjectGen('Dr. Salvacion L. GARCERA ','Nora N. GALLANO '),
    setObjectGen('Anthony A. GILE ','Caredine C. GUBAN '),
    setObjectGen('Rochelle M. GLOVA ','Ray Artimus P. HINLO '),
    setObjectGen('Annie V. LIEDO ','Melvin HATE, '),
    setObjectGen('Isabelle Grace L. LOSABIA ','Sharmila A. IMAYSAY '),
    setObjectGen('Flora Mae R. LUBAG ','Fr. Jonathan LETADA '),
    setObjectGen('Erika A. MENDAZA ','Richel L. LOZANO '),
    setObjectGen('Jomari L. MIRABETE ',' Edcel L. LOÑOSA '),
    setObjectGen('Franz Jeninna A. MIRANDA ','Berne G. MALANO '),
    setObjectGen('Mailen N. MURO ' ,'Meriam MANALO '),
    setObjectGen('Jhoan M. REDOBLADO ','Dr. Marife B. MOLATO '),
    setObjectGen('Elric Justin C. RICO ','Aires C. MOLLASGO '),
    setObjectGen('Airahvie Marienelle M. ROVELO ','Lalyn NARVAJA '),
    setObjectGen('Rubilyn R. SENIAS ','Essa Kurt RASTRULLO ' ),
    setObjectGen('AI B. TORREVILLAS ','Levi B. VELASCO '),
    setObjectGen('Roman Pablo A. ZUÑIGA II','Merriam B. VIBAL '),



];

export const schoolOfEducationArtsAndSciences = {
    courses, 
    facultyAndStaff
}