import {setObjectGen} from '../../../helpers';

const newStudents = [
    'Develop in the students a strong commitment to promote the national identity by discerning and preserving desirable traditions, values, and Filipino culture',
    'Help the students acquire productive and entrepreneurial skills, work ethics, occupational, and self-knowledge essential for making intelligent career choices',
    'Develop the students’ abilities in arts and sports',
    'Develop the students’ higher order thinking skills; and',
    'Develop in the student’s strong religious characteristics, awareness of and concern for the needy, indigenous people, people with disabilities, and other marginalized members of society.,'
];

const programs = [
    'JUNIOR HIGH: Grade 7 – 10 (With ESC Government Subsidy)',
    'FREE SECONDARY DISTANCE EDUCAION PROGRAM (FSDEP) Grade 7 to 10 (EVERY SATURDAY - TRIMESTER)',
];

const facultyAndStaff = [
    setObjectGen('Ma. Cecilia C. Arbo, MABioEd','Principal'),
    setObjectGen('Sarah Jane M. Almayda, MABioEd','Fulltime Faculty  -  Science'),
    setObjectGen('Patrick Armario','Fulltime Faculty  -  Science'),
    setObjectGen('Jaycebelle A. Berjuega ','Fulltime Faculty  -  English'),
    setObjectGen('Claire Salve T. Boliver  ','Fulltime Faculty  -  TLE'),
    setObjectGen('Melchor B. Borcelis  ','Fulltime Faculty  -  CLE'),
    setObjectGen('Presciosa Mae O. Brizuela  ','Fulltime Faculty  -  English'),
    setObjectGen('Mary June Ann M. Datur  ','Fulltime Faculty  -  TLE'),
    setObjectGen('Concepcion L. Dellosa  ','Fulltime Faculty  -  TLE'),
    setObjectGen('Rosario D. Geralde ','Fulltime Faculty  -  Filipino'),
    setObjectGen('Lexter Gonzales ','Fulltime Faculty  -  MAPEH'),
    setObjectGen('Jenelyn B. Jagonob ','Fulltime Faculty  -  Math'),
    setObjectGen('Mark. B. Latorena ','Fulltime Faculty  -  AP'),
    setObjectGen('Ramon III P. Logoc ','Fulltime Faculty  -  CLE'),
    setObjectGen('Randy G. Loresto ','Fulltime Faculty  -  AP'),
    setObjectGen('Reynel D. Manlangit ','Fulltime Faculty  -  TLE'),
    setObjectGen('Ramil A. Mesa ' ,'Fulltime Faculty  -  Math'),
    setObjectGen('Mary Joy M. Mirabete ','Fulltime Faculty  -  MAPEH'),
    setObjectGen('Allan O. Navea, MAEM ','Fulltime Faculty  -  Math'),
    setObjectGen('Jenny Lyn R. Nebres ','Fulltime Faculty  -  Science'),
    setObjectGen('John Paul P. Nocedo ','Fulltime Faculty  -  Filipino'),
    setObjectGen('Ryan L. Paulino','Fulltime Faculty  -  MAPEH'),
    setObjectGen('Marivet Ll. Ravalo ','Fulltime Faculty  -  Math'),
    setObjectGen('Amalia I. Rito, MAEd','Fulltime Faculty  -  Science'),
    setObjectGen('Jomel B. Romero ','Fulltime Faculty  -  English'),
    setObjectGen('Marilou F. Salvado ','Fulltime Faculty  -  Filipino'),
    setObjectGen('Andres A. Toledo','Fulltime Faculty  -  MAPEH'),
    setObjectGen('Jeaniel S. Bernarte ','Probationary   -  AP'),
    setObjectGen('Jenny Rose G. Herrera ','Probationary  -  CLE'),
    setObjectGen('Elijah Lee ','Probationary  -  English'),
];

export const juniorHighSchoolDepartment = {
    newStudents,
    programs,
    facultyAndStaff
}