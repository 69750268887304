import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners} from '../../../data/web';
import {seniorHighSchool} from '../../../data/web/Admission';
import {RenderList} from '../../../components/molecules/web';
import {Paragraph} from '../../../components/atoms/web';

const SeniorHighSchool = () => { 

    const {requirements,grade11,transferees,oldstudents,contacts,registrar,assessment} = seniorHighSchool;

    return (
        <BaseWithBannerAndOverview data={navlinks.admission.items} title='Senior High School Department' banner={banners.AdmissionBannerObj}>
            <Paragraph color='primary' variant='h6' bold> Incoming Grade 11 </Paragraph>
            <RenderList header='Application Requirements :' data={requirements} />
            <Paragraph color='primary' variant='h6' bold> For Transferees/Returnees (Grade 12) </Paragraph>
            <RenderList header='Application Requirements :' data={grade11} />
            {/*<RenderList header='Transferees / Returnees Grade 12' data={transferees} />*/}
            {/*<RenderList header='Old Students' data={oldstudents} />*/}
            <RenderList header='Senior High School AGTC' data={contacts} />
            <RenderList header='Registrar and Cashier' data={registrar} />
            <RenderList header='Assessment' data={assessment} />
        </BaseWithBannerAndOverview>
    )
}

export default SeniorHighSchool
