import Carousel from 'react-material-ui-carousel'
import {makeStyles} from '@material-ui/core/styles';
import {Image} from '../../atoms/web';
import {EnrollmentPortal} from '../../molecules/web';
import systemCover from '../../../assets/images/others/SYSTEMCOVER.jpg';
import {unchunkArrayValues} from '../../../helpers';
import { facilities } from '../../../assets/images';

const useStyle = makeStyles({
    image: {
        height: '450px',
        width: '100%',
        backgroundSize: 'cover',
    },
    
    carousel: {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        position: 'relative'
    },
});


const Slider = ({ data, size = null }) => {
    console.log(data)

    const {carousel, image} = useStyle();

    const setSize = size ? size : carousel;

    const renderCorousel = () => {
        return (
            <Carousel
                autoPlay={false}
                indicators={false}
                animation="slide"
                navButtonsAlwaysVisible={true}
                next={()=>{}}
                prev={()=>{}}
            >          
                { data.map(({alt, img}, index) => {
                   return (
                       <div className={image} key={index}>
                        <Image alt={alt} source={img}  />
                       </div>
                   ) 
                })}
            </Carousel>
        )
    }

    const renderDefaultSlide = () => {
        return (
            <Image alt='default slide' source={systemCover} />
        )
    }

    return (
        <div className={setSize}>
            <EnrollmentPortal />
            {data.length == 0 ? renderDefaultSlide() : renderCorousel()}
        </div>
    )
}
export default Slider
