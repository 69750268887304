import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners} from '../../../data/web';
import {Paragraph, BoldSpan} from '../../../components/atoms/web';
import Grid from '@material-ui/core/Grid';
import {presidents} from '../../../assets/images';

const History = () => {

    return (
        <BaseWithBannerAndOverview data={navlinks.about_us.items} title='History' banner={banners.AboutUsBannerObj}>
           <Paragraph>
                The Divine Word College of Legazpi (DWCL) sprang into existence from a small school, Liceo de Albay (LDA), a diocesan school of Caceres for boys. In 1947 the LDA was entrusted to Fr. Juan Carullo, a retired army chaplain. The members of the Board of Trustees were then Msgr. Justiniano Romano, Fr. Euferto Alberto, Fr. Alfonso Molina, Fr. Guilermo Rejante, Fr. William Rehente and Fr. Rustico Burce. The school building was located at Corners Rizal St. and Maria Clara St. (now Fr. Joseph L. Bates St.), Legazpi City. The lot was donated by the Calleja family of Legazpi City.
            </Paragraph>
           <Paragraph>
                In 1952, the LDA was ravaged by Typhoon Trix. Financial incapacity became a major problem for its survival. To save the school from possible closure, the then Bishop Flaviano B. Ariola, D.D. offered the school to the Societas Verbi Divini (SVD) Congregation. Fr. Joseph L. Bates, SVD, negotiated for the lease of the building and lot. Fr. Bates left Holy Name Academy in Bohol and took over the administration of the school sporting a new image as Divine Word High School (DWHS) in SY 1960-1961 with Msgr. Honesto A. Moraleda as Principal and Frt. Florante Camacho, SVD as Regent. The new school kept its exclusivity for the education of boys with the help of Msgr. Nestor C. Cariño.
            </Paragraph>
            <Paragraph>
                Aware of the need for additional services in secondary basic education, the school later went co-educational. The Night High School was also opened for working students. In anticipation of the growing popularity of the school to children at lower levels, Fr. Bates bought a five-hectare land at Washington Drive, Legazpi City from Atty. Juan Imperial. A three-storey building was immediately constructed for basic education students in 1971. This site, eventually referred to as the North Campus, became the home of basic education in 1977.
            </Paragraph>
           <Paragraph>
                <BoldSpan>Grade School Department</BoldSpan>. In 1965, the Grade School started its operation and got government recognition in 1966. In 1991, the Pre-School was also opened. In its aim of improving the quality of education for the young, the Grade School took initial steps and submitted itself for a Preliminary Survey Visit by the Philippine Accrediting Association of Schools, Colleges, and Universities (PAASCU) on February 26-27, 2007. It had a formal visit on December 2-3, 2008, and a Resurvey Visit on November 28-19, 2011. The department was granted Level II accreditation status valid for 2012-2017 and then again for 2017-2022. Recently, the Grade School was reaccredited for another five years, valid until February 2028, and now eligible for Level III accreditation.
            </Paragraph>
           <Paragraph>
                <BoldSpan>Junior High School Department</BoldSpan>. In 1992, the High School offered a Science Oriented Curriculum. Fifty-Six (56) freshmen students were admitted. However, due to financial drawbacks, the program was eventually phased out in May 2001. In 1998, the High School opened a Free Secondary Distance Educational Program (FSDEP) for adult working students. In that same year, on July 31, 1998, the High School was granted Level 1 accreditation by PAASCU. In response to the demand to upgrade its quality of education, the High School underwent another accreditation for Level II and was granted such for a five-year period starting in 2004. After the November 10-11, 2008 PAASCU vi sit, the department was granted a clean accreditation until 2013. Another PAASCU visit on December 9-10, 2013 resulted in the department's again being granted a clean accreditation for five years, valid up to 2019. In August 2019, it received yet another Level II re-accreditation valid up to November 2024, making it eligible for Level III. With the nationwide implementation of the Senior High School program in 2016, this department was renamed the Junior High School. 
            </Paragraph>
           <Paragraph>
                <BoldSpan>Senior High School Department</BoldSpan>. Participating in the government program of innovations in the basic education system, the DWCL started accepting Grade 11 students in Senior High School in 2016, offering Academic and Technical-Vocational Livelihood Tracks. In the Academic Track, the DWCL SHS department offers the following strands: Science, Technology, Engineering, and Mathematics (STEM), Accountancy, Business, and Management (ABM), and General Academic Strand (GAS). In the Technical-Vocational Livelihood Track, the following strands were offered: Food and Beverage Services (FBS), Cookery, and Computer System Servicing (CSS). In anticipation of an increasing number of enrollees in the Senior High School, DWCL started the construction of a three-storey building located at the North Campus in 2016. In 2017, Br. Yosep Undung, SVD, Ph.D., was appointed as the first Vice President for Basic Education to supervise the Grade School, Junior High School, and Senior High School. Due to the increased student interest in HUMSS, the strand was added to the offerings; meanwhile GAS strand was frozen.  In January 2023, the Senior High School department underwent the PAASCU preliminary accreditation, and was deemed eligible for Formal Survey in 2024.
            </Paragraph>
           <Paragraph>
                <BoldSpan>Tertiary Schools</BoldSpan>. In 1965, the institution started offering Liberal Arts, Commerce, Education, and Secretarial programs, and thus was renamed Divine Word College of Legazpi (DWCL). In 1971, in consortium with the St. Gregory the Great Seminary in Tabaco, Albay, DWCL started offering the AB-Philosophy program.
            </Paragraph>
           <Paragraph>
                In response to the manpower needs of the growing number of offices and entrepreneurial agencies in the Bicol Region, short-term programs for Accounting Aides and Salesmanship were opened in June 1974. As part of the expansion program in technology and business, the BS Civil Engineering program was opened two years later.
            </Paragraph>
           <Paragraph>
                In recognition of the exemplary performance of DWCL, the AIDES Program of CHED RO V named DWCL the Center for Development in Commerce and Business Education in 1983. In 1984, the Accountancy Department, in cooperation with the Philippine Association of Collegiate Schools of Business (PACSB) and the Commission on Audit (COA), piloted a three-year program in Government Accounting and Auditing in DWCL. In the same year, in coordination with the Regional Science Teaching Center at Aquinas University, the Centership for the Training of Private School Elementary Teachers for the implementation of the New Elementary School Curriculum (NESC) in the Bicol Region was established in DWCL by the Fund for Assistance to Private Education (FAPE).
            </Paragraph>
           <Paragraph>
                In 1987, the BSE and BEED curricula were reopened as a response to the increasing need for more teachers in public schools. With greater demands for computer-literate professionals and office staff, the Commerce Department opened in 1993 the BS Computer Science (BSCS), BS in Secretarial Administration (BSSA) programs, and the Computer Secretarial Course. The BSSA program was later changed to BS in Office Administration (BSOA).
            </Paragraph>
           <Paragraph>
                Education programs were integrated into the AB-curricular programs to qualify the graduates for the Licensure Examination for Teachers (LET). Other developments followed: the Commerce Department offered a specialization in Entrepreneurship and the Accountancy Department introduced a ladderized curriculum. The CPA Review Center was also inaugurated in 1997.
            </Paragraph>
           <Paragraph>
                During the SY 2001-2002, the departments were reclassified as colleges.  Subsequently, the Colleges of Accountancy, Engineering, and Commerce opened the following non-board programs: BS in Management and Accountancy (BSMA), BS in Information Technology (BSIT), BS in Hospitality Management (BSHM) and BS in Business Administration (BSBA), major in Marketing and Legal Management. In October 2001, the programs of the Colleges of Accountancy, Commerce, Education, and Liberal Arts were awarded Level 1 PAASCU Accreditation.
            </Paragraph>
           <Paragraph>
                In 2003, the College of Engineering opened the BS in Electrical Engineering Program.    Further, all computer program offerings were transferred from the College of Commerce, resulting in the renaming of the College as the College of Engineering and Computer Studies (COECS). It had an adjunct office called Information Technology Support Center. On November 21, 2003, the curricular programs of the Colleges of Liberal Arts, Education, Commerce, and Accountancy merited Level II PAASCU Accreditation for three years.
            </Paragraph>
           <Paragraph>
                The College of Nursing, after being granted the “Permit-to-Operate” in the SY 2004- 2005, admitted freshmen applicants to its BS in Nursing (BSN) program and trainees to its six- month Caregiver Training program. On March 10, 2005, the Bachelor of Science in Hospitality Management (BSHM) program was granted Government Recognition by CHED ROV. In SY 2007-2008, the CHED and TESDA granted a Certificate of Authority and a Certificate of Recognition to the BSHM to operate ladderized programs in Commercial Cooking NCII, Housekeeping NCII, Food and Beverage NCII, Bartending NCII, Baking/Pastry Production NCII, Front Office NCII, and Travel Service NCII. The BSHM underwent PAASCU consultancy visit on July 29-30, 2010. Meanwhile, the Bachelor of Science major in Legal Management and Marketing was granted the renewal of permit for the SY 2005-2006. 
            </Paragraph>
           <Paragraph>
                In the same school year, the verticalized business education program was implemented by the College of Commerce.  Towards the end of 2005, the Colleges of Commerce and Accountancy, and the Graduate School of Business and Management (GSBM) were merged to become the College of Business Education. A year later, the DWCL Call Center (Incubation and Training Center) was opened in a joint venture with two internationally-known entities: The Information and Communications Technology (ICT) Training and Business Process Outsourcing (BPO) Experts under the John F. Kennedy Foundation and Five9 of USA. During the same year, DWCL joined the Zonal Research Council for Region IV and V based at UP Los Baños. It also pioneered the first On-Line Enrollment System in the Bicol Region.
            </Paragraph>
           <Paragraph>
                To immortalize the memory of the founder, the DWCL Alumni Association, in cooperation with the Legazpi City Office of the Mayor and City Council, succeeded in renaming Maria Clara St., Legazpi City (fronting the college campus) the Fr. Joseph L. Bates St. School Year 2007-2008 saw the birth of the programs BS in Biology, BS Psychology and BS Mathematics at the College of Liberal Arts. With the birth of these new programs, on November 10, 2009, the College of Education and the College of Arts and Sciences were merged to become the College of Education, Arts, and Sciences (CEAS). In SY 2008-2009, the Education, Arts and Sciences, and Business Programs were granted Level II re-accreditation by the PAASCU for five (5) years, valid until 2013.  Government recognition was granted subsequently for Bachelor of Science in Psychology (2010), Bachelor of Science in Biology (2011), Bachelor of Science in Mathematics (2011), Bachelor of Library and Information Science (2012), and Diploma in Midwifery (2012). 
            </Paragraph>
           <Paragraph>
                In 2016 the existing programs were clustered under five schools:  the School of Education, Arts and Science (SEAS) (formerly College of Arts and Sciences and College of Education), the School of Business, Management and Accountancy (SBMA) (formerly College of Business, Management, and Accountancy), the School of Engineering and Computer Studies (SOECS) (formerly College of Engineering  and  Computer Studies, with the Bachelor of Library and Information Science (BLIS) now transferred to the School), School of Nursing (SoN) (formerly College of Nursing), and the School of Hospitality Management (SHoM) (formerly College of Hospitality Management). SHoM was granted PAASCU Level II Accreditation in 2022, valid up to September 2027, and eligible for Level III application.  In 2019 the School of Arts and Sciences, Elementary, Secondary Education, and Business Administration programs were granted PAASCU Level II re-accreditation, valid up to February 2024. In the meantime, starting SY 2020-2021, SEAS applied and received government permits to open new programs: Bachelor of Physical Education, Bachelor of Special Needs Education, and Bachelor in Human Services. 
            </Paragraph>
            <Paragraph>
                <BoldSpan>Academic Linkages</BoldSpan>. In 2016, in view of the Commission on Higher Education (CHED) K-12 Transition Faculty Development Grants, Dr. Rose Marie Salazar-Clemeña, then the Assistant Vice President for Academic Affairs, spearheaded efforts to forge a collaborative relationship with De La Salle-College of Saint Benilde (DLS-CSB). Under this partnership, DLS-CSB, as the Delivering Higher Education Institution, agreed to conduct the MS in Hospitality and Tourism Management Program, with DWCL as the Host Higher Education Institution.  Several faculty members from DWCL and other institutions in the Bicol Region enrolled in this program as CHED scholars.  Later, under a continuing partnership agreement, DLS-CSB accepted DWCL Hospitality Management students for practicum in its CSB Hotel and provided professional enrichment activities to faculty, staff, and alumni of DWCL.
            </Paragraph>
            <Paragraph>
                In 2018, Dr. Rose Marie Salazar-Clemeña, this time as Vice President for Academic Affairs, worked out a partnership with the University of San Carlos (USC) and Divine Word College of Legazpi (DWCL) in the areas of faculty exchange and development, research collaboration, sharing of curricular programs, library resources, and other aspects for the mutual benefit of the institutions, particularly in the field of psychology. The collaboration consisted specifically of (1) USC Visiting Faculty assigned to handle courses in the DWCL BS in Psychology Program and (2) USC’s conducting an off-shore Master of Arts in Clinical Psychology Program with DWCL as host institution.
            </Paragraph>
            <Paragraph>
                <BoldSpan>Learning Facilities</BoldSpan>. In April 2015, the St. Martha Training Center for Culinary Arts and Related Skills (SMTC-CARS) was inaugurated under the presidency of Rev. Fr. Crispin A. Cordero, SVD, with Chef Roberto G. Clemeña as the first Resident Executive Chef.  The SMTC-CARS was set up in answer to the need for a separate venue for in-campus practicum of students in the School of Hospitality Management (SHoM).  Here, a “Hatchery Program” is offered, where students spend, outside of a classroom environment, hours of on-the-job training based on industry standards.
            </Paragraph>
            <Paragraph>
                In 2018, the Office of the Vice President for Academic Affairs, under Dr. Rose Marie Salazar-Clemeña, established the Student Learning Assistance Center (SLAC) to provide formal and systematic tutorial programs—in mathematics, initially, but in other areas as well, eventually. This was a strategic initiative aligned with the objective of improving students’ academic performance to achieve the goal of Academic Excellence under the Key Result Area of Academics.
            </Paragraph>
            <Paragraph>
                <BoldSpan>Research and Extension Services</BoldSpan>. In the summer of 2000, the Center for Development, Research, and Extension Service (CDRE) was organized under the aegis of the Office of the President. This development consequently caused the separation of the Community Extension Service (CES), and Research divisions became independent bodies.
            </Paragraph>
            <Paragraph>
                <BoldSpan>Research</BoldSpan>. The first director, Dr. Ma Lourdes Sabater, together with some faculty members, conducted several researches and other research activities, thus reaching the goal of a Dynamic Research Culture. Research on various research topics were published in 2002.  These included Wife Abuse in Albay, Entrepreneurship Development Program: An Evaluation, and Employability of Successful DWCL Graduates, to cite a few. Due to the active conduct of research activities, in 2006, the DWCL became a member of the Bicol Consortium for Industry and Energy Research and Development (BCIERD) organized by the Department of Science and Technology (DOST), and hosted a seminar workshop on Research Conceptualization and Proposal Packaging. DWCL was an active member of BCIERD until 2013. In 2014, headed by a new research director, Br. Yosep Undung, SVD, the research office produced the first issue of the DWCL Research Journal PANANAW, which was published in SY 2014-2015, with an ISSN 2423-2270. The journal is published annually to date. During the strategic planning in 2019, the DWCL research goal was modified from ‘Dynamic’ to ‘Enriched Research Culture’. Some faculty members and graduate school students contribute their articles for publication in the DWCL Research Journal PANANAW. In August 2018, the new research director, Dr. Pedro Bernaldez, while continuing to publish the DWCL Research Journal PANANAW, also introduced the monthly publication of Monographs, individually published research articles of the faculty members from different department/schools. In the strategic planning in 2022, the DWCL research strategic goal was modified to ‘Intensified Research Culture’. More faculty members are now involved in research activities.
            </Paragraph>
           <Paragraph>
            <Paragraph>
                <BoldSpan>Community Extension Service</BoldSpan>. Back in the year 2000, the formal implementation of the Community Extension Service (CES) thrusts took off with the Enterprise Development Project (EDP) as a lending program for the livelihood activities of beneficiary employees. The EDP funded the Tulong Aral for poor but deserving students. With initial funding from the Bilance in the Netherlands, the CES Adopt-a-Barangay outreach project was inaugurated in Barangay Dita in 1998. This program was expanded to Barangay Lamba in 2000. When the Cordaid (formerly Bilance) funding ended, DWCL sustained the program. Then the DWCL continued to serve the adopted barangays Dita and Lamba with various activities such as seminars on credit policies, strategic review and planning seminars, small business appreciation seminar workshops, banana chips and tamarind balls making, etc. In 2004, the CES office, through the DWCL Development Foundation, received a donation from the United Nations Women’s Guild, Geneva, Switzerland for 200 children in Barangay Lamba, Legazpi City. In 2005, further linkages in community services were expanded to barangay Taysan, Tula-tula, 14 Ilawod, Maoyod, Curzada, Gogon, Bitano, and Dapdap. In coordination with CWTS students, the CES in 2007 expanded its services to help in Build-a-House Project for 300 Typhoon Reming victims at Anislag Resettlement Site. Services through the Free Secondary Distance Education Program (FSDEP) have continuously been the best feature of the CES program of the DWCL. In the meantime, various social services are extended to the nearby communities such as Buhatan, Bariis, Cruzada, Anislag, Cagbacong, Bagacay, Maslog, Imalnog, Mariawa, etc. To date, the CES has facilitated various activities in different additional barangays around Legazpi.  In December 2022, the DWCL adopted a new barangay in Inang, Sorsogon.
            </Paragraph>
                <BoldSpan>Graduate School of Business and Management</BoldSpan>. As part of the institutional thrust of developing would-be-leaders in the business community, the Graduate School of Business and Management (GSBM) was opened in 1978, offering a Master in Business Administration (MBA) Program with no area of specialization. The objective was to develop generalists rather than specialists. In 1980, DWCL was one of the 13 Charter Member-Institutions of the Bicol Conference for Higher Education (BCHE), the forerunner of the Bicol Foundation for Higher Education (BFHE). In 1992, the MBA Program was enriched by offering five majors of specializations: Financial Management, Development Management, Enterprise Management, Business Education; and General Administration.
            </Paragraph>
           <Paragraph>
                To address the need for officer-level manpower in the government sector, the Master in Public Management was offered in 1995. It was also during the same year that, DWCL was identified as one of the Graduate School Centers throughout the country, together with St. Louis University in Baguio City, University of Sto. Tomas, and San Jose Recoletos in Cebu, that were accredited to accept FAPE Scholars, with a specialization in Business Education. 
            </Paragraph>
           <Paragraph>
                <BoldSpan>The Administrators through the Years</BoldSpan>. Fr. Joseph L. Bates, SVD served as the school president for a period of nine years, from 1961 to 1970, interrupted only from 1968 to 1970, when he went to the United States. Fr. Donald Mulrenan, SVD took over as acting president in the interim. From 1970 onwards, Filipinos served as president of the Divine Word College of Legazpi in their respective terms:
            </Paragraph>
            <Grid container justify='center' style={{ padding:'2rem' }}>
                <img src={presidents} alt='Presidents' />
            </Grid>
        </BaseWithBannerAndOverview>
    )
}

export default History
