import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners} from '../../../data/web';
import {RenderList} from '../../../components/molecules/web';
import { gradeSchool } from '../../../data/web/Admission/GradeSchool';
import { BoldSpan, Paragraph } from '../../../components/atoms/web';

const GradeSchool = () => { 
    const {kinder,transferees,age,contacts,registrar,assessment} = gradeSchool;
    return (
        <BaseWithBannerAndOverview data={navlinks.admission.items} title='Grade School Department' banner={banners.AdmissionBannerObj}>
            
            <Paragraph color='primary' variant='h6' bold><BoldSpan> Incoming Pre-Kindergarten and Kindergarten </BoldSpan></Paragraph> 
            <RenderList header='Application Requirements :' data={kinder} />
            <Paragraph color='primary' variant='h6' bold><BoldSpan> Incoming Grade 1 </BoldSpan> </Paragraph> 
            <RenderList header='Application Requirements :' data={transferees} />
            <Paragraph color='primary' variant='h6' bold>Transferees/Returnees (Grades 2-6)</Paragraph> 
            <RenderList header='Application Requirements :' data={age} />
            <RenderList header='Grade School AGTC' data={contacts} />
            <RenderList header='Registrar and Cashier' data={registrar} />
            <RenderList header='Assessment' data={assessment} />
        </BaseWithBannerAndOverview>
    )
}

export default GradeSchool
