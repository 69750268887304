import {setListContent} from '../../../helpers';

const categories = [
    setListContent('Announcements', '/updates/announcements'),
    setListContent('News and Events', '/updates/news-and-events'),
]


export const updates = {
    categories,
}