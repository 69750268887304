const procedure = [
    'Online Application Form For Admission (OAFFA) for College & Graduate School (Link posted at our FB Page:AGTC at DWCL)',
    '2 pieces of the same recent picture, 2x2, color, with white background',
    'Photocopy of Form 138 (Except for Transferees)',
    'Photocopy of PSA Birth Certificate',
    'Photocopy of Marriage Certificate for married women',
    'Original Certificate of Good Moral Character',
    'BSA : Qualifying Exams',
    'BSA(Accountancy)- Grades 11 & 12 Cards',
];
 const requirements = [
    'Photocopy of Cert. of Grades (COG) or Transcript of Records (TOR)',
    'Certificate of Transfer Credential or Honorable Dismissal',
    'Transcript of Records for evaluation Purposes or Certified List of Subjects taken indicating all ratings and credits earned',
    'Long white folder'
 ];

 const contacts = [
    'Mobile Numbers: 09394723917 | 09351751763',
    'Emails: college.admissions@dwc-legazpi.edu | college.admissions2@dwc-legazpi.edu',
];
export const college = {
    procedure,
    requirements,
    contacts
}