const requirements = [
    '1. Duly accomplished Online Application Form for Admission (OAFFA)',
    '2. 2 pcs. Recent 2x2 pictures (colored with white background)',
    '3. Report Card (Form 138)',
    '4. 1 long sized white folder',
    '5. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate from their medical doctor recommending regular schooling is required',
];

const grade11 = [
    '1. Duly accomplished Online Application Form for Admission (OAFFA)',
    '2. 2 pcs. Recent 2x2 pictures (colored with white background)',
    '3. Report Card (Form 138)',
    '4. 1 long sized white folder',
    '5. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate from their medical doctor recommending regular schooling is required',

];

const transferees = [
    '1st & 2nd sem, G11 Cards/List of SHS subjects certified by school Principal or Registrar',
];

const oldstudents = [
    'Report Card',
];
const contacts = [
    'Mobile Numbers: 09756802419 (TM) | 09517001676 (TNT) ',
    'Email: shsagtc@dwc-legazpi.edu',
];

const registrar = [
    'Mobile Number: 09070013874',
];

const assessment = [
    'Mobile Number: 09187354006',
];
export const seniorHighSchool = {
    requirements,
    grade11,
    transferees,
    oldstudents,
    contacts,
    registrar,
    assessment
}