import React from 'react'
import {BaseWithBannerAndOverview} from '../../../components/templates/web';
import {navlinks, banners} from '../../../data/web';
import {BoldSpan, Paragraph} from '../../../components/atoms/web';

const CampusMinistry = () => { 

    return (
        <BaseWithBannerAndOverview data={navlinks.student_services.items} title='CENTER FOR SPIRITUALITY & MISSION' banner={banners.StudentServicesBannerObj}>
            <Paragraph>
                The Center for Spirituality and Mission is concerned with the Christian formation, and holistic spiritual enhancement of the academe. It maintains a watchful eye on the community's practices of worship, witnessing, and service through the celebration of the eucharist, recollections and retreats, etc. and responsive to the social needs of the people in the region that answers to the call and challenge for national developments. This office is headed by the CSM Director, assisted by the SVD priests, regents, Religious Educations teachers, and student assistants.
            </Paragraph>
            <Paragraph color='primary' variant='h6' bold><BoldSpan> Programs & Services </BoldSpan></Paragraph> 
            <Paragraph color='primary' variant='h6' bold>
                <BoldSpan> Religious Education Unit </BoldSpan>
            </Paragraph>
            <Paragraph>
                - Religious Education Program on Divinian Spirituality
            </Paragraph>
            <Paragraph>
                - Recollections and retreats
            </Paragraph>
            <Paragraph>
                - Symposia and seminars regarding religious and spiritual matters
            </Paragraph>
            <Paragraph color='primary' variant='h6' bold>
                <BoldSpan> Campus Ministry Unit </BoldSpan>
            </Paragraph>
            <Paragraph>
                - Liturgical Services (Holy Mass, Para-liturgical services etc.)
            </Paragraph>
            <Paragraph>
                - Liturgical Formation for Church servers and volunteers.
            </Paragraph>
            <Paragraph>
                - Liturgical Music Ministry.
            </Paragraph>
            <Paragraph>
                - Pilgrimages to Shrines etc.
            </Paragraph>
            <Paragraph color='primary' variant='h6' bold>
                <BoldSpan> Community Extension Service and Social Concerns Unit </BoldSpan>
            </Paragraph>
            <Paragraph>
                - Medical & Dental Mission
            </Paragraph>
            <Paragraph>
                - Calamity Assistance Program
            </Paragraph>
            <Paragraph>
                - Share to Care & Christmas Cheers Program
            </Paragraph>
            <Paragraph>
                - Skills and Livelihood Training
            </Paragraph>
            <Paragraph>
                - Livelihood Project
            </Paragraph>
            <Paragraph>
                - Seminar/ Training on Social issues
            </Paragraph>
            <Paragraph>
                - Coastal Clean up drive and Distribution of Trash bins to DWCL Adopted communities
            </Paragraph>
            <Paragraph>
                - Free Secondary Distance Education Program (FSDEP) for the working OSY & Young Adults
            </Paragraph>
            <Paragraph>
                - Establishing linkages with LGU’s, GO’s, HEI’s, Social Welfare Institutions, and concerned parishes.
            </Paragraph>
            <Paragraph>
                - Soliciting fund through submission of Project Proposals to local and foreign Funding agencies.
            </Paragraph>
            
        </BaseWithBannerAndOverview>
    )
}

export default CampusMinistry
