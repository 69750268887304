import React from "react";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { renderIconFromObject } from "../../../helpers";
import { Paragraph, ListItemIconWithText } from "../../../components/atoms/web";
import { contactUs } from "../../../data/web";
import Facebook from "@material-ui/icons/Facebook";

const useStyles = makeStyles({
  noPadding: {
    padding: 0,
  },
  listIcon: {
    minWidth: 0,
    color: "#4b83cc",
    padding: "0 1rem",
    paddingTop: ".2rem",
  },
  listLink: {
    color: "black",
  },
});

const GetInTouch = () => {
  const { contacts, contactIcon, contactsText } = contactUs;
  const { listIcon, listLink, noPadding } = useStyles();

  return (
    <>
      <Grid item md={3} />
      <Grid item md={9} style={{ flexWrap: "wrap" }}>
        <Paragraph variant="h3" color="primary" bold>
          Get in Touch
        </Paragraph>
        <Paragraph>Please contact us through:</Paragraph>
        {contacts.map((val, index) => {
          return (
            <ListItemIconWithText key={index} text={contactsText[val]}>
              {renderIconFromObject(val, contactIcon, "primary", true)}
            </ListItemIconWithText>
          );
        })}
        <ListItem
          component="a"
          href="https://www.facebook.com/gsdepartmentagtc/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL GS Department - Admissions, Guidance and Testing Center"
          />
  
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/dwclsao"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL Student Affairs Office"
          />
        </ListItem>
        <ListItem
          component="a"
          href="https://www.facebook.com/dwclegazpisvd"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL Junior High School Department"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/agtcdwcl"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="AGTC at DWCL"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/dwclagtcshs/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL Senior High School AGTC "
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/DWCL.CSEB"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="College Student Executive Board"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/SHS-DWCL-Health-Services-101843914970748/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="SHS DWCL Health Services "
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/DWCLGS1961"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="Divine Word College of Legazpi - Grade School"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/DWCL-AGTC-Psychosocial-Support-101808551565844/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL - AGTC Psychosocial Support "
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/studentactivitiesofficedwclshs/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="Student Activities Office - DWCL SHS"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/dwclagtcjhs"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="JHS-AGTC DWCL"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/DWCL-Information-and-Communications-Technology-Center-131683651768000"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL Information and Communications Technology Center"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/dwclsoecs/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL - School of Engineering and Computer Studies"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/profile.php?id=100094666834966"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL - School of Business Management and Accountancy"
          />
        </ListItem>

        <ListItem
          component="a"
          href="https://www.facebook.com/DWCL-Community-Extension-Service-CES-291449391189984/"target="blank"
          alignItems="flex-start"
          className={noPadding}
          
        >
          <ListItemIcon className={listIcon}>
            <Facebook />
          </ListItemIcon>
          <ListItemText
            className={listLink}
            primary="DWCL Community Extension Service - CES"
          />
        </ListItem>
      </Grid>
    </>
  );
};

export default GetInTouch;
