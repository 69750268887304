const grade7 = [
    '1. Duly accomplished Online Application Form for Admission (OAFFA)',
    '2. 2pcs. recent 2x2 picture (colored with white background)',
    '3. Report Card (Form 138)',
    '4. 1 long sized white folder',
    '5. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate from their medical doctor recommending regular schooling is required',
];

const transferees  = [
    '1. Report Card (Form 138)',
    '2. Duly accomplished Online Application Form for Admission (OAFFA)',
    '3. 2 pcs. recent 2x2 pictures (colored with white background)',
    '4. 1 Long sized White Folder',
    '5. Recommendation form from Previous School',
    '6. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate" from their medical doctor recommending regular schooling is required',
];

const oldstudents  = [
    'Report Card',

];
const contacts = [
    'Mobile Number: 09503916464 ',
    'Email:jhsagtc@dwc-legazpi.edu',
];

const registrar = [
    'Mobile Number: 09070013874',
];

const assessment = [
    'Mobile Number: 09187354006',
];
export const juniorHighSchool = {
    grade7,
    transferees,
    oldstudents,
    contacts,
    registrar,
    assessment
}