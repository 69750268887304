import { setObjectGen } from '../../../helpers';

const enrollment = [
    {header: 'ONLINE', title: 'Online Enrollment', link: 'https://online.dwc-legazpi.edu/' },
    {header: 'ON-SITE', title: 'Washington drive, Legazpi', link: '#'}    
];

const sched = [
    setObjectGen('New Students and Transferee', 'JULY 11, 2022 - AUGUST 12, 2022'),
    setObjectGen('Old Students', 'JULY 11, 2022 - AUGUST 12, 2022'),
];

const edu = ['Pre-kinder', 'Kinder', 'Grade school', 'Junior high school', 'Senior high school'];

export const onlineEnrollment = {
    enrollment,
    sched,
    edu
}