import { setObjectGen } from '../../helpers'

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PhonelinkIcon from '@material-ui/icons/Phonelink';
import RoomIcon from '@material-ui/icons/Room';
import MobileIcon from '@material-ui/icons/PhoneAndroid';


const contactIcon = {
    email: EmailIcon,
    phoneS: PhoneIcon,
    phoneN: PhoneIcon,
    telefax: PhonelinkIcon,
    south: RoomIcon,
    north: RoomIcon,
    soecs:MobileIcon,
    sbma:MobileIcon,
    seas:MobileIcon,
    shom:MobileIcon,
    nursing:MobileIcon,
    gsbm:MobileIcon,
    gsprincipal:MobileIcon,
    jhsprincipal:MobileIcon,
    shsprincipal:MobileIcon,
    supply:MobileIcon,
    registrar:MobileIcon,
    sassessment:MobileIcon,
    basiced:MobileIcon,
    hsposting:MobileIcon,
    presoffice:MobileIcon,
    vpaa:MobileIcon,
    marketing:MobileIcon,
    pages:PhonelinkIcon,
}

const contactsText = {
    email: 'Email: dwclinfo@dwc-legazpi.edu',
    phoneN: 'Telephone Number (North CAMPUS) +63 52 742 7966',
    phoneS: 'Telephone Number (South CAMPUS) +63 52 480 2148',
    telefax: 'Telefax: 480-3108',
    south: 'South Campus - cor.J.P Rizal and Fr. J.L. Bates Sts., Albay District, Legazpi City 4500 Philippines',
    north: 'North Campus - Washington Drive, Legazpi City 4500 Philippines',
    soecs: '(SOECS) - 0907 000 5431',
    sbma: '(SBMA) - 0909 078 0329',
    seas: '(SEAS) - 0909 078 0252',
    shom: '(SHOM) - 0909 078 0255',
    nursing: '(SON) - 0909 078 0263',
    gsbm: '(GSBM) - 0907 001 3876',
    gsprincipal: '(GS Principal) - 0997 894 2483 / 0930 137 9864',
    jhsprincipal: '(JHS Principal) - 0909 078 0266',
    //shsprincipal: '(SHS Pricipal) - 0909 078 0271',
    supply: '(Supply Office) - 0923 571 4969',
    registrar:'(College Registrar) - 0909 078 0273',
    sassessment:'(Assessment) 0907 001 3875',
    basiced:'(Basic ed. Reg./Cashier North Campus) - 0907 001 3874',
    hsposting:'(Basic ed. Assessment) - 0918 735 4006',
    presoffice:'(Pres. Office) - 0907 001 3873',
    vpaa: '(VPAA) - 0907 001 3859',
    marketing:'(Marketing)- 0967 206 7429',
    contacts:'CONTACT NUMBERS',
    pages:'FACEBOOK PAGES',
    gs:'Divine Word College of Legazpi- Grade School ',
    gsagtc:'DWCL GS Department - Admissions, Guidance and Testing Center',
    jhs:'DWCL Junior High School Department  ',
    jhsagtc:'Jhs-AGTC at DWCL  ',
    jhslibrary:'DWCL Jhs Library',
    shs:'DWCL Senior High School',
    shsagtc:'DWCL SHS AGTC ',
    shslibrary:'DWCL SHS Library',
    shshealth:'SHS DWCL Health Services',
    shspsycho:'DWCL - AGTC Psychosocial Support ',
    shssao:'Student Activities Office - DWCL SHS',
    cseb:'College Student Executive Board ',
    saocollege:'DWCL Student Affairs and Services',
    registratcollege:'DWCL College Registrars Office ',
    assessmentcollege:'DWCL Assessment ',
    ictc:'DWCL Information and Communications Technology Center',
    soecsdwcl:'DWCL - School of Engineering and Computer Studies ',
    sbmacollage:'School of Business, Management and Accountancy ',
    cesdwcl:'DWCL Community Extension Service - CES ',
    cmo:'DWC-Campus Ministry Office ',
    libaraydwcl:'Divine Word College Library-Legazpi City ',
    


}

const contacts = ['email', 'phoneN', 'north', 'phoneS', 'south','telefax','pages']//'gs','gsagtc','jhslibrary','jhslibrary','shs','shsagtc','jhslibrary',
//'shshealth','shspsycho','shssao','cseb','saocollege','registratcollege','assessmentcollege','ictc','soecsdwcl','sbmacollage','cesdwcl','cmo','libaraydwcl'];

const fields = [
    setObjectGen('Name', 'text'),
    setObjectGen('Email', 'email'),
    setObjectGen('Contact number', 'number'),
    setObjectGen('Subject', 'text'),
    setObjectGen('Message', 'textarea'),
];

export const contactUs = {
    contactsText,
    contacts,
    fields,
    contactIcon
}