import { setObjectGen } from '../../../helpers';

const objectives = [
    'Equip the students with information, media and technology skills, learning and innovative skills, effective communication skills, and life and career skills',
    'Develop desirable knowledge, skills, attitudes, and competencies essential for making intelligent career choices',
    'Increase students’ motivation to develop and excel in their talents',
   'Enhance students’ intellectual, critical and analytical thinking skills to help them deal effectively with varied life situations',
    'Enable the students to acquire skills that would make them better prepared for post-high school life; and',
    'Inculcate in them the Divinian core values of excellence, justice, professionalism, peace, service, and integrity of creation for a holistic formation and development.',
];

const courses = [
    'ABM – (Accountancy, Business, & Management)',
    'GAS – (General Academic Strand)',
    'HUMMS-(Humanities and Social Sciences)',
    'STEM –(Science, Technology, Engineering and Mathematics)',
        'STEM HS -(STEM for Health and Sciences)',
        'STEM ME -(STEM for Mathematics and Engineering)',
    
];

const vocational = [
    setObjectGen(' Home Economics with the following Specializations:', [
        'Cookery',
        'Bread & Pastry Production',
        'Food and Beverages NC II',
        
    ]),  
];

const facultyAndStaff = [
    setObjectGen('Emma T. Alcantara , MIE', ' Principal'),
    setObjectGen(' '),
    setObjectGen('FULL-TIME TENURED FACULTY'), 
    setObjectGen('Jozald P. Abache, LPT'),
    setObjectGen('Joebelle M. Ala, LPT'),
    setObjectGen('Sarajane B. Andes, LPT'),
    setObjectGen('Rochelle Ann C. Apuli, LPT'),
    setObjectGen('Marjorie May R. Balatucan, LPT'),
    setObjectGen('David D. Gomez, LPT'),
    setObjectGen('Caredine C. Guban, MAFilEd, LPT'),
    setObjectGen('Jahnna Mae Litana, LPT'),
    setObjectGen('Ma. Fe A. Lopez, LPT'),
    setObjectGen('Grace L. Muñoz, LPT'),
    setObjectGen('Lalyn A. Narvaja, LPT'),
    setObjectGen('Rose Ann B. Osabel, LPT'),
    setObjectGen('Cristine Mae B. Biso I, LPT'),
    setObjectGen(''),
    setObjectGen('FULL-TIME PROBATIONARY FACULTY'),
    setObjectGen('Robiniel G.  Brinces, LPT'),
    setObjectGen('Maria Sophia D. Gomez, LPT'),
    setObjectGen('Vincent  Ll. Madriñan, LPT'),
    setObjectGen('Byron A. Majadas, LPT'),
    setObjectGen('Shieralyn B. Villar, LPT'),
    setObjectGen(''),
    setObjectGen('FULL-TIME CONTRACTUAL FACULTY'),
    setObjectGen('Mr. Mark Christian Balana'),
    setObjectGen('Engr. Reggie L. Maigue'),
    setObjectGen('Mr. John Patrcik  G. Maulawin'),
    setObjectGen('Mr. Vincent O. Nicol'),
    setObjectGen('Ms. Carmelle Nicole M. Nieto'),
    setObjectGen(''),
    setObjectGen('PART-TIME  FACULTY'),
    setObjectGen('Ma. Daria N. Labalan, CPA'),
    setObjectGen('Glenn M. Bonga'),
];

export const seniorHighSchoolDepartment = {
    objectives,
    courses,
    vocational,
    facultyAndStaff
}