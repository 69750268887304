// export const apiClient = 'http://api.dwcl-legazpi.edu:8000';

import axios from 'axios';

const Api = axios.create({
    baseURL: 'https://api.dwc-legazpi.edu',
    //baseURL: 'http://45.124.59.11:8000',
    //baseURL: 'http://localhost:8000',
    //baseURL: 'http://10.2.10.29:8000',
    //baseURL: 'https://dwc-legazpi.edu',
    withCredentials: true,
});

export default Api;
