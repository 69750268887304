import { setObjectGen } from '../../../helpers';

const objectives = [
    'Deepen the child’s personal faith and relationship with Christ through His teachings',
    'Assist the child to strive for excellence through a relevant curriculum that will develop functional literacy',
    'Develop the child’s intellectual potentials by emphasizing critical thinking and sustaining the inculcation of values, integrated in all learning areas',
    'Awaken the child’s artistic talents and creative abilities through various co-curricular offerings and activities',
    'Promote religious education and character formation through the observance of various liturgical activities inside and outside the school, and awareness of the needs of his/her fellowmen especially the less fortunate',
    'Deepen the child’s appreciation of his/her rich Filipino culture and provide him/her ways and avenues to uphold his/her rights and duties as a productive citizen in the society; and',
    'Enable the child to develop a well-rounded personality by providing him/her holistic education.',
];

const program = [
    'PRE-SCHOOL LEVEL: Pre-Kindergarten; Kindergarten',
    'PRIMARY LEVEL: Grade I to Grade III',
    'INTERMEDIATE LEVEL: Grade IV to Grade VI',
];

const facultyAndStaff = [
    setObjectGen('Geraldine A. Barrun, LPT, MAEngEd', 'Principal'),
    setObjectGen('Annelyn G. Loretcha, LPT', 'Adviser, Pre-Kindergarten St. Ann & St. Joachim'),
    setObjectGen('Vanie Diane M. Bondoy, LPT','Adviser, Kindergarten St. Michael the Archangel & St. Raphael the Archangel'),
    setObjectGen('Sharon A. Bahoy, LPT','Adviser, Grade 1 – St. James the Apostle'),
    setObjectGen('Marietta M. Olitin, LPT','Adviser, Grade 1 – St. Catherine Laboure '),
    setObjectGen('Annie M. Mendioro, LPT','Adviser, Grade 2 – St. Augustine'),
    setObjectGen('Ivy Jane J. Ogao, LPT','Adviser, Grade 2 – St. John the Baptist Subject Area Coordinator-Mathematics and Science'),
    setObjectGen('Mabel V. Santiago, LPT','Adviser, Grade 3 – St. Paul the Apostle'),
    setObjectGen('Princess Monique A. Echalas, LPT','Adviser, Grade 3 – St. Gregory the Great'),
    setObjectGen('Renz Kryztoffer S. Barce, LPT','Adviser, Grade 4 - St. Thomas Aquinas'),
    setObjectGen('Meryl M. Berjuega, LPT','Adviser, Grade 4 – St. Jerome'),
    setObjectGen('Maria Irva Eurene C. Arimado, LPT','Adviser,  Grade 5 – Our Lady of Peñafrancia'),
    setObjectGen('Pamela D. Lorica, LPT','Adviser, Grade 5 – Our Lady of Salvation Communication Arts Coordinator (English, Filipino and Mother Tongue)'),
    setObjectGen('Liezl B. Brazil, LPT','Adviser, Grade 6 – St. Arnold Janssen'),
    setObjectGen('Loida P. Lolo, LPT','Adviser, Grade 6 – St. Joseph Freinademetz, SVD Subject Area Coordinator for- Araling Panlipunan, Edukasyon sa Pangkabuhayan at Pangkalusugan and Computer'),
    setObjectGen('Ma. Venus A. Carillo, LPT','Subject Teacher in MAPEH, Araling Panlipunan and Filipino'),
    setObjectGen('Babylyn P. Lozares, LPT','Subject Area Coordinator -Christian Living Education and MAPEH'),
    setObjectGen('Dindo L. Mendivel, LPT','Subject Teacher in Filipino '),
    setObjectGen(' '),
    setObjectGen('Non-Teaching Staff: '),
    setObjectGen('Marie Joane B. Balonzo','Secretary'),
    setObjectGen('Aira M. Salazar, RL','Librarian'),
    setObjectGen('Airis C. Mollasgo, RPm','Guidance Associate'),
    setObjectGen('Evelyn L. Losabia','Registrar, Basic Education'),
    setObjectGen('Jasmin B. Villanueva','Clerical Staff, Registrar’s Office'),
    setObjectGen('Jennylyn S. Lorilla','Encoder, Registrar’s Office'),
    setObjectGen('Raquel P. Morco','Posting & Assessment In-Charge, Basic Education'),
    setObjectGen('Agnes B. Gabito','Cashier, Basic Education'),
    setObjectGen('Odette Imelda M. Magalona, RN','School Nurse'),
    setObjectGen('Dr. Randie M. Asuncion','School Physician'),
    setObjectGen('Marie Antonette Suguitao, DDM','School Dentist'),
    setObjectGen('John Elliot L. Moyo','Director, Basic Education Student Formation Office'),
    setObjectGen('Rizaldo U. Avila','Maintenance Personnel'),
    setObjectGen('Algie N. Nodalo','Maintenance Personnel'),
    setObjectGen('Arlene Mabilin','Maintenance Personnel'),
];

export const gradeSchoolDepartment = {
    objectives,
    program,
    facultyAndStaff
}