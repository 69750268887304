const programs = [
    'Academic Scholarship',
    'DWCL Music Ministry',
    'DWCL Chorale',
    'The Channel',
    'College Student Executive Board (CSEB)',
    'Student Assistant Program',
    'Varsities (Basketball/Volleyball - Men & Women)',
    'Presidential Decree 451',
    'Presidential Decree 577',
    'St. Arnold Jansen Scholarship Grant for BSED Major in Mathematics and Science',
    'CEAL Scholarship',
];

const loans = [
    'PVAO Scholarship',
    'Alfredo Yuchengco Foundation Scholarshio',
    'Student Financial Assistance Program (STUPAF)',
    'PGMA Higher Education Loan Program',
    'Student Assistance Fund for Education',
    'Gokongwei Brothers Foundation (GBF) TeachStem Scholarship Program',
    'DOST 2023 Junior Level Science Scholarship',
];

const ched = [
    'Full PESFA',
    'Half PESFA',
];

const unifast = [
    'Tertiary Education Subsidy',
    'Tulong-Dunong Grant-In-Aid',
];

export const scholarship = {
    programs,
    loans,
    ched,
    unifast
}