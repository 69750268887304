const requirements = [
    'Online Application Form For Admission (OAFFA) for College & Graduate School (Link posted at our FB Page:AGTC at DWCL)',
    '2 pieces of the same recent picture, 2x2, color, with white background',
    'Birth Certificate issued by Philippine Statistics Authority (PSA)',
    'Photocopy of Marriage Certificate for married women',
];
const contacts = [
    'Mobile Numbers: 09394723917 | 09351751763',
    'Emails: college.admissions@dwc-legazpi.edu | college.admissions2@dwc-legazpi.edu',
];
export const graduateSchool = {
    requirements,
    contacts
}