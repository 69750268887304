
import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paragraph } from '../../components/atoms/web';
const useStyles = makeStyles({ 
    ytiframe:{
        width: '680px',
        height: '0px',
        //border: '25px solid #4b83cc',
    },
    iframe: {
        //float: 'left',
        display: 'inline-block',
        margin:  'auto',
        width: '410px',
        height:'500px',
        border: '0px solid #4b83cc',

        
        
    },
    ztiframe:{
                //float: 'left',
                display: 'inline-block',
                margin:  'auto',
                width: '900px',
                height:'550px',
                border: '0px solid #4b83cc',
    },
})

const WelcomeMessage = () => {

    const { googleMap, iframe, ytiframe, ztiframe } = useStyles();

    return ( 
            
            //<iframe src="https://www.facebook.com/plugins/video.php?height=550&href=https%3A%2F%2Fwww.facebook.com%2Fdwclegazpisvd%2Fvideos%2F285767354059373%2F&show_text=false&width=900&t=0" className={ztiframe}></iframe>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdwclegazpisvd&tabs=timeline&width=410&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" className={iframe}></iframe>
            
    )
}

export default WelcomeMessage
