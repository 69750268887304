import { Paragraph } from "../../../components/atoms/web";

const kinder = [
    'Application Requirements :',
    '1. Age requirements',
    'a. Pre-Kindergarten: 4 years old / Child is about to turn 4 during the school year (E.g., For SY 2023-2024; Birthdate should be within January 1 -October 31, 2019)',
    'b. Kindergarten: 5 years old/ Child is about to turn 5 during the school year (E.g., For SY 2023-2024; Birthdate should be within January 1 - October31, 2018)',
    '2. Duly accomplished Online Application Form for Admission (OAFFA)',
    '3. 2 pcs. recent 2x2 picture (colored with white background)',
    '4. Birth Certificate issued by the Philippine Statistics Authority (PS) or National Statistics Office (NSO) or Certified True Copy of birth from Local Civil Registrar',
    '5. 1 long sized white folder',
    '6. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate" from their medical doctor recommending regular schooling is required',
];

const transferees  = [
    'Application Requirements : ',
    '1. Duly accomplished Online Application Form for Admission (OFFA)',
    '2. 2 pcs. recent 2x2 pictures (colored with white background)',
    '3. Birth Certificate issued by the Philippine Statistics Authority (PSA) or National Statistics Office (NSO) or Certified True Copy of birth from Local Civil Registrar',
    '4. Certificate of Completion of Kindergarten',
    '5. Report Card (Form 138) (If applicable)',
    '6. 1 long sized white folder',
    '7. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate from their medical doctor recommending regular schooling is required',

];

const age  = [
    'Application Requirements: ',
    '1. Duly accomplished Online Application Form for Admission (OAFFA)',
    '2. 2 pcs. recent 2x2 pictures (colored with white background)',
    '3. Report Card (Form 138)',
    '4. 1 long sized white folder',
    '5. If applicant is diagnosed with any mental health concerns, a "Fit to Study Certificate" from their medical doctor recommending regular schooling is required',

];
const contacts = [
    'Mobile Numbers: 09978942483 (TM) | 09301379864 (TNT)',
    'Email: gsagtc@dwc-legazpi.edu',
];

const registrar = [
    'Mobile Number: 09070013874',
];

const assessment = [
    'Mobile Number: 09187354006',
];
export const gradeSchool = {
    kinder,
    transferees,
    age,
    contacts,
    registrar,
    assessment
}