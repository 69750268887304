import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paragraph } from '../../components/atoms/web';
const useStyles = makeStyles({ 

    ztiframe:{
                //float: 'right',
                display: 'inline-block',
                margin:  'auto',
                width: '900px',
                height:'500px',
                border: '0px solid #4b83cc',
    },
})

const WelcomeMessage = () => {

    const { ztiframe } = useStyles();

    return ( 
 
            <iframe src="https://www.facebook.com/plugins/video.php?height=500&href=https%3A%2F%2Fwww.facebook.com%2Fdwclegazpisvd%2Fvideos%2F285767354059373%2F&show_text=true&width=900&t=0" className={ztiframe}></iframe>

    )
}

export default WelcomeMessage
