import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { LinkText } from '../../atoms/web';

const useStyles = makeStyles({
   bold: {
        fontWeight: 600
    },
});

const OurContacts = () => {

    const {bold} = useStyles();

    return (
       <>
            <Typography gutterBottom variant="h6" className={bold}>
                Our Contacts
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
                TELEPHONE NUMBERS
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                +63 52 742-7966 (North Campus)
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                +63 52 480 2148 (South Campus)
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                480-3108 (Registrar)
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                0967 206 7429 (Marketing)
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
                E-MAIL
            </Typography>

            <LinkText text='dwclinfo@dwc-legazpi.edu' link='/contact-us' />


       </>
    )
}

export default OurContacts
